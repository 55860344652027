<template>
  <v-app-bar id="default-app-bar" app absolute class="v-bar--underline" color="transparent" :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl" height="70" flat>
    <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />

    <default-drawer-toggle class="hidden-sm-and-down" />

    <v-toolbar-title class="font-weight-light text-h5" v-text="selectedCompanyName" />

    <v-spacer />

    <v-bottom-navigation v-if="selectedCompanyId > 0" v-model="navbar" color="primary">
      <v-btn value="devices">
        <span>Devices</span>
        <v-icon>mdi-cellphone</v-icon>
      </v-btn>

      <v-btn value="apps">
        <span>Apps</span>
        <v-icon>mdi-apps</v-icon>
      </v-btn>

      <v-btn value="scripts">
        <span>Scripts</span>
        <v-icon>mdi-script-text-outline</v-icon>
      </v-btn>

      <v-btn value="configs">
        <span>Configs</span>
        <v-icon>mdi-tune</v-icon>
      </v-btn>

      <v-btn value="settings">
        <span>Settings</span>
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>

      <v-btn value="license">
        <span>Licenses</span>
        <v-icon>mdi-license</v-icon>
      </v-btn>

      <v-btn value="users">
        <span>Users</span>
        <v-icon>mdi-account-group</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn class="ml-3 mr-4" elevation="1" fab small v-on="on">
          <v-icon :color="currentGlobe">
            mdi-web
          </v-icon>
        </v-btn>
      </template>
      <span>{{
        currentGlobeId == 1
          ? "Mobile server working"
          : "Mobile server not working"
      }}</span>
    </v-tooltip>

    <default-account />
  </v-app-bar>
</template>

<script>
// Utilities
import { get, sync } from 'vuex-pathify'

export default {
  name: 'DefaultBar',
  components: {
    DefaultAccount: () =>
      import(
        /* webpackChunkName: "default-account" */
        './widgets/Account'
      ),
    DefaultDrawerToggle: () =>
      import(
        /* webpackChunkName: "default-drawer-toggle" */
        './widgets/DrawerToggle'
      ),
    DefaultGoHome: () =>
      import(
        /* webpackChunkName: "default-go-home" */
        './widgets/GoHome'
      ),
    // DefaultNotifications: () =>
    // import(
    //   /* webpackChunkName: "default-notifications" */
    //   './widgets/Notifications'
    // ),
    // DefaultSearch: () =>
    // import(
    //   /* webpackChunkName: "default-search" */
    //   './widgets/Search'
    // ),
  },
  data: () => ({
    currentGlobe: '#f82328',
    greenGlobe: '#45e689',
    redGlobe: '#f82328',
    currentGlobeId: 0,
  }),
  computed: {
    ...sync('app', ['drawer', 'mini']),
    ...get('company', ['selectedCompanyId', 'selectedCompanyName']),
    name: get('route/name'),
    path: get('route/path'),
    navbar: {
      get() { return this.path?.replace('/', '') },
      set(value) {
        const route = '/' + value
        if (this.path !== route) {
          this.$router.push(value)
        }
      },
    },
  },
  watch: {
    selectedCompanyId() {
      this.checkNavbarState()
    },
  },
  created() {
    // Listen to score changes coming from Signal events
    this.$signalHub.$on(
      'signal-status-changed',
      this.onSignalConnectionChanged,
    )

    this.checkNavbarState()
  },
  beforeDestroy() {
    this.$signalHub.$off(
      'signal-status-changed',
      this.onSignalConnectionChanged,
    )
  },
  methods: {
    checkNavbarState() {
      if (this.selectedCompanyId === 0 && this.path !== '/devices') {
        this.$router.replace('/devices')
      }
    },
    onSignalConnectionChanged(isConnected) {
      if (isConnected) {
        this.currentGlobe = this.greenGlobe
        this.currentGlobeId = 1
      } else {
        this.currentGlobe = this.redGlobe
        this.currentGlobeId = 0
      }
    },
  },
}
</script>
